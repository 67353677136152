'use strict';

const TagsUtils = require('utils/TagsUtils');
const TreeItemTools = require('utils/TreeItemTools');
const Utils = require('utils/utils');


const releaseTree = BackTree.Collection.extend({

  release: {},
  content: {},
  treeView: {},

  setRelease: function setRelease(release) {
    this.release = release;
  },

  setContent: function setContent(content) {
    this.content = content;
  },

  setTreeView: function setTreeView(treeView) {
    this.treeView = treeView;
  },

  toggleNodeMetadata: function toggleNodeMetadata(model, enabled, $element) {
    var parentIsArray = model.get('parentIsArray');
    var jsonPath = TreeItemTools.getModelJsonPath(model);
    if (parentIsArray && jsonPath) {
      model.set('disabled', !enabled);
      if (enabled) {
        this.release.removeDisabledMetadataFromReleaseValue(model, jsonPath, $element);
      } else {
        this.release.addDisabledMetadataToReleaseValue(model, jsonPath, $element);
      }
    }
  },

  removeNode: function removeNode(model, doNotPushOperation) {
    var parentIsArray = model.get('parentIsArray');
    if (parentIsArray) {
      var sourcePath = TreeItemTools.getModelJsonPath(model);
      this.release.removeItem(sourcePath);
      model.set('isRemoveChange', true);
      this.reorderReleaseNodes(model, doNotPushOperation);
      model.remove();
    }
  },

  refreshModel: function refreshModel(originalModel, nodeJsonPath, newValue) {
    if (newValue === null) {
      this.removeNode(originalModel, true);
      return;
    }
    var title = originalModel.get('title');
    var ref = originalModel.get('ref');
    var parentIsArray = originalModel.get('parentIsArray');
    var oneOfType = originalModel.get('oneOfType');
    var disabled = originalModel.get('disabled');
    var disableStartDate = originalModel.get('disableStartDate');
    var enableStartDate = originalModel.get('enableStartDate');
    var programDisabled = originalModel.get('programDisabled');
    // Recreate node with new value
    var updatedModel = this.recreateNode(title, ref, newValue, parentIsArray, oneOfType, disabled, disableStartDate, enableStartDate, programDisabled, true);

    var originalGuid = originalModel.get('guid');
    var targetIndex = this.getNodeIndexFromGuid(originalGuid);
    var parentRef = Utils.getParentPath(nodeJsonPath, '.');
    var parentModel = this.getNodeFromRef(parentRef);
    // Delete original model
    originalModel.remove();

    // Insert updated model
    parentModel.add(updatedModel, { at: targetIndex });

    return updatedModel;
  },

  recreateNode: function recreateNode(title, ref, obj, parentIsArray, oneOfType, disabled, disableStartDate, enableStartDate, programDisabled, open) {
    var nodes = [];
    if (obj && obj['@metadata']) {
      oneOfType = (obj['@metadata'].type) ? obj['@metadata'].type : '';
      disabled = (obj['@metadata'].disabled) ? obj['@metadata'].disabled : false;
      disableStartDate = (obj['@metadata'].disableStartDate) ? obj['@metadata'].disableStartDate : '';
      enableStartDate = (obj['@metadata'].enableStartDate) ? obj['@metadata'].enableStartDate : '';
      programDisabled = (disableStartDate || enableStartDate) ? true : false;
    }
    if (_.isObject(obj)) {
      for (var key in obj) {
        if (key == '@metadata') {
          continue;
        }
        nodes.push(this.recreateNode(key, ref ? ref + '.' + key : key, obj[key], _.isArray(obj), oneOfType, disabled, disableStartDate, enableStartDate, programDisabled, false));
      }
    }
    var guid = Utils.guid();
    var result = {
      parentIsArray: parentIsArray || false,
      open: open,
      title: title,
      ref: ref,
      guid: guid,
      value: obj,
      nodes: nodes,
      oneOfType: oneOfType,
      disabled: disabled,
      disableStartDate: disableStartDate,
      enableStartDate: enableStartDate,
      programDisabled: programDisabled,
    };
    return result;
  },

  createTreeNode: function createTreeNode(title, ref, obj, parentIsArray, oneOfType) {
    var nodes = [];
    var disabled = false;
    var disableStartDate = '';
    var enableStartDate = '';
    var programDisabled = false;
    if (obj && obj['@metadata']) {
      oneOfType = (obj['@metadata'].type) ? obj['@metadata'].type : '';
      disabled = (obj['@metadata'].disabled) ? obj['@metadata'].disabled : false;
      disableStartDate = (obj['@metadata'].disableStartDate) ? obj['@metadata'].disableStartDate : '';
      enableStartDate = (obj['@metadata'].enableStartDate) ? obj['@metadata'].enableStartDate : '';
      if (disableStartDate || enableStartDate) {
        programDisabled = true;
      }
    }
    if (_.isObject(obj)) {
      for (var key in obj) {
        if (key == '@metadata') {
          continue;
        }
        nodes.push(this.createTreeNode(key, ref ? ref + '.' + title : title, obj[key], _.isArray(obj), oneOfType));
      }
    }
    var guid = Utils.guid();
    var result = {
      parentIsArray: parentIsArray || false,
      open: false,
      title: title,
      ref: ref ? ref + '.' + title : title,
      guid: guid,
      value: obj,
      nodes: nodes,
      oneOfType: oneOfType,
      disabled: disabled,
      disableStartDate: disableStartDate,
      enableStartDate: enableStartDate,
      programDisabled: programDisabled,
    };
    return result;
  },

  addArrayLine: function addArrayLine(nodeView, typeToAdd, contentValue, pageToCopy) {
    var nodeModel = nodeView.model;
    var ref = nodeModel.get('ref');
    var jsonPath = TreeItemTools.getModelJsonPath(nodeModel);
    if (!contentValue) {
      contentValue = this._createArrayLineContent(typeToAdd, nodeModel, jsonPath, pageToCopy);
    }
    contentValue = this._updateTags(contentValue, jsonPath);
    var releaseCollection = this.release.getValue(jsonPath);
    var pathOfNewItem = [jsonPath, releaseCollection.length].join('.');
    releaseCollection.push(contentValue);
    this.release.pushOperation(false, 'add', pathOfNewItem, contentValue);
    this.release.setValue(jsonPath, releaseCollection);

    var nodeId = this._getNextTableId(nodeModel);

    var oneOfIncluded = typeToAdd ? typeToAdd : nodeModel.get('oneOfType');
    var newChild = this.createTreeNode(nodeId, ref, contentValue, true, oneOfIncluded);
    nodeModel.add(newChild);
    nodeModel.set('open', true);

    this.scrollToNewArrayElement(ref, nodeId);
    return newChild;
  },

  scrollToNewArrayElement: function scrollToNewArrayElement(ref, nodeId) {
    var key = ref + '.' + nodeId + '.remove';
    var $element = $('[data-ref="' + key + '"]');
    $element.delay(200).queue(function() {
      $('html, body').animate({ scrollTop: $element.offset().top - 300 }, 1000, () => {
        $element.focus().trigger('focus');
      });
      $(this).dequeue();
    });
  },

  _createArrayLineContent: function _createArrayLineContent(typeToAdd, nodeModel, jsonPath, pageToCopy) {
    var contentValue = '';

    if (typeToAdd) {
      var schema = this.release.get('schema');
      var subSchema = schema.definitions[typeToAdd.replace('#/definitions/', '')];
      subSchema = _.clone(subSchema);
      subSchema.definitions = _.clone(schema.definitions);

      if (pageToCopy) {
        contentValue = window.jsonSchemaDefaults(subSchema);

        var newValues = Object.assign(window.jsonSchemaDefaults(subSchema), pageToCopy.attributes.value);
        newValues.pageId = '';
        newValues.url = '';
        delete newValues._id;
        contentValue = newValues;
      } else {
        contentValue = window.jsonSchemaDefaults(subSchema);
      }

      if (contentValue && typeof contentValue === 'object') {
        contentValue['@metadata'] = {
          type: typeToAdd,
        };
      }

    } else {
      var previousCollection = this.release.getValue(jsonPath);
      var subSchema = TreeItemTools.getSchema(nodeModel, jsonPath + '.' + previousCollection.length, this.release);
      contentValue = window.jsonSchemaDefaults(subSchema);
    }


    return contentValue ? contentValue : '';
  },

  _updateTags: function _updateTags(contentValue, jsonPath) {
    if (contentValue && typeof contentValue === 'object') {
      var contentId = this.content.get('id');
      if (TagsUtils.jsonPathEqualsTaggedRootPath(contentId, jsonPath)) {
        var tagsMetadata = this.release.get('tagsMetadata');
        if (contentValue['@metadata']) {
          contentValue['@metadata'].tags = (tagsMetadata && tagsMetadata.selectedTags && tagsMetadata.selectedTags !== TagsUtils.UNTAGGED_TAGS_STRING && tagsMetadata.selectedTags !== TagsUtils.ALL_TAGS_STRING) ? tagsMetadata.selectedTags : '';
        } else {
          contentValue['@metadata'] = (tagsMetadata && tagsMetadata.selectedTags && tagsMetadata.selectedTags !== TagsUtils.UNTAGGED_TAGS_STRING && tagsMetadata.selectedTags !== TagsUtils.ALL_TAGS_STRING) ? { tags: tagsMetadata.selectedTags } : { tags: '' };
        }
      }
    }
    return contentValue;
  },

  _getNextTableId: function _getNextTableId(model) {
    if (!model || !model.nodes() || model.nodes().models.length === 0) {
      return 0;
    }

    var { models } = model.nodes();
    var maxId = 0;
    for (var i in models) {
      var current = parseInt(models[i].get('title'));
      maxId = maxId > current ? maxId : current;
    }
    return maxId + 1;
  },

  reorderReleaseNodes: function reorderReleaseNodes(movedElementModel, doNotPushOperation) {
    var isRemoveChange = movedElementModel.get('isRemoveChange');
    var sourcePath = TreeItemTools.getModelJsonPath(movedElementModel);
    var parentPath = Utils.getParentPath(sourcePath, '.');
    if (isRemoveChange) {
      var sourceIndex = parseInt(Utils.getLastPathPart(sourcePath), 10);
      var oldLength = movedElementModel.parent()._nodes.length;
      var targetIndex = oldLength - 1;
      var targetPath = parentPath + '.' + targetIndex;

      if (sourcePath !== targetPath) { // No need to update references if the last item was deleted
        for (var i = sourceIndex + 1; i < oldLength; i++) {
          var newIndex = i - 1;
          var newRef = parentPath + '.' + newIndex;
          var childModel = movedElementModel.parent()._nodes.at(i);
          this._updateModelRef(childModel, newRef, newIndex);
        }
      }
      if (!doNotPushOperation) {
        this.release.pushOperation(false, 'remove', sourcePath);
      }
    } else {
      var targetPath;
      var targetValue;
      var releaseNodes = this.release.getValue(parentPath);
      var releaseNodesCopy = _.cloneDeep(releaseNodes);
      var getNewNodeValueFailed = false;
      for (var i = 0; i < releaseNodes.length; i++) {
        var newRef = parentPath + '.' + i;
        var childModel = movedElementModel.parent()._nodes.at(i);
        if (_.isEqual(childModel, movedElementModel)) {
          targetPath = newRef;
        }
        var newNodeValue = this._getNewNodeValue(childModel, releaseNodesCopy);
        if (newNodeValue) {
          releaseNodes[i] = newNodeValue;
          if (targetPath && !targetValue) {
            targetValue = _.cloneDeep(newNodeValue);
          }
        } else {
          getNewNodeValueFailed = true;
          break;
        }
        this._updateModelRef(childModel, newRef, i);
      }

      if (getNewNodeValueFailed) {
        this._reorderReleaseNodesDeprecated(movedElementModel);
      }

      this.release.pushOperation(true, 'remove', sourcePath);
      this.release.pushOperation(true, 'add', targetPath, targetValue);
    }
    // Rebind element's drag'n'drop events & bootstrap select inputs
    this.treeView.bindDragAndDrop();
    this.treeView.initSpecialInputs();
  },

  _getElementIndex: function _getElementIndex(elementModel) {
    var elementRef = elementModel.get('ref');
    return parseInt(Utils.getLastPathPart(elementRef), 10);
  },

  _getNewNodeValue: function _getNewNodeValue(model, releaseNodesCopy) {
    var modelIndex = this._getElementIndex(model);
    if (releaseNodesCopy && releaseNodesCopy.length && !isNaN(modelIndex)) {
      return releaseNodesCopy[modelIndex];
    } 
      return null;
    
  },

  _updateModelRef: function _updateModelRef(model, newRef, newIndex) {
    model.set('ref', newRef);
    model.set('title', newIndex);
  },

  _reorderReleaseNodesDeprecated: function _reorderReleaseNodesDeprecated(movedElementModel) {
    var jsonPathParent = TreeItemTools.getJsonPath(movedElementModel.parent());

    var releaseNodes = this.release.getValue(jsonPathParent);
    for (var i = 0; i < releaseNodes.length; i++) {
      var childModel = movedElementModel.parent()._nodes.at(i);
      releaseNodes[i] = childModel.get('value');
      var newRef = jsonPathParent + '.' + i;
      this._updateModelRef(childModel, newRef, i);
    }
  },

  getNodeFromRef: function getNodeFromRef(ref) {
    var returnNode = null;
    if (!ref) {
      return this;
    } 
      TreeItemTools.traverseTreeNodes(this, (treeNode) => {
        if (treeNode.get('ref') == ref) {
          returnNode = treeNode;
          return false;
        }
      });
    
    return returnNode;
  },

  getNodeFromGuid: function getNodeFromGuid(guid) {
    var returnNode = null;
    if (!guid) {
      return this;
    } 
      TreeItemTools.traverseTreeNodes(this, (treeNode) => {
        if (treeNode.get('guid') == guid) {
          returnNode = treeNode;
          return false;
        }
      });
    
    return returnNode;
  },

  getNodeIndexFromGuid: function getNodeIndexFromGuid(guid) {
    var returnIndex = null;
    if (!guid) {
      return this;
    } 
      TreeItemTools.traverseTreeNodes(this, (treeNode, index) => {
        if (treeNode.get('guid') == guid) {
          returnIndex = index;
          return false;
        }
      });
    
    return returnIndex;
  },

  getRefFromJsonPath: function getRefFromJsonPath(jsonPath) {
    if (!jsonPath) {return '';}

    var pathElements = jsonPath.split('.');
    var ref = '';
    for (var elementIndex in pathElements) {
      var currentPathElement = pathElements[elementIndex];
      var workingRef = TreeItemTools.getConcatEndPath(ref, currentPathElement);
      var node = this.getNodeFromRef(workingRef);
      if (node) {
        if (node.get('parentIsArray')) {
          var index = parseInt(currentPathElement);
          var model = node.collection.models[index];
          ref = TreeItemTools.getConcatEndPath(ref, model.get('title'));
        } else {
          ref = TreeItemTools.getConcatEndPath(ref, node.get('title'));
        }
      }
    }

    return ref;
  },

});


module.exports = releaseTree;
